const en_msg = {
    placeholder: "Enter IČO number or company name",
    searching: "Searching for matching companies",
    nothingFound: "No company found for the entered value.",
    startWritting: "Start typing",
    branch: "Branch",
    international: {
        waiting: "A moment of patience, we have to check everything in data from more than 190 countries of the world",
        heading: "Going through",
        companies: "companies",
        managers: "managers",
        owners: "owners",
        shareholders: "shareholders",
        searching: "One sec please, we search in 110M entities from 190 countries.",
        downService: "Search is currently unavailable, please try again soon.",
        errors: {
            undefined: "Data is not available right now, try it later please",
            noCredit: "No credit left",
            noUser: "You are not logged in or in any organization",
            moodys: "Data is not available right now, try it later please",
            noCreditUsage: "You don't have permission to use credit"
        }
    },
    changeType: {
        cz: "You search Czech entities only, for international searches change",
        global: "Do you want to search in Czech entities only. Go",
        didntFound: "Didnt found you are looking for? Go international",
        here: "here"
    },
    organizationId: "IČO",
    types: {
        cz: "Czech subjects",
        global: "International search",
        person: "Person"
    },
    autoscreening: {
        filter: {
            placeholder: "Name of company or IČO"
        },
        subject: "Company",
        organization_id: "IČO",
        state: "State",
        lastChange: "Last change",
        page: "Page {{ page }} of {{ maxPage }}"
    },
    person: {
        errors: {
            emptyName: "Name is empty.",
            notFullName: "Name is not in format <FirstName><Space><LastName>.",
            shortFirstName: "First name is short.",
            shortSecondName: "Last name is short.",
            emptyBirthdate: "Birthdate is empty.",
            emptyCountries: "No country is selected."
        },
        countriesHelp: "You can select multiple countries. For example, in the case of a different place of birth and permanent residence, or when changing nationality, it is better to enter all relevant countries.",
        birthdateHelp: "If you do not know the exact date, enter the closest possible one. If you only know the month and year, enter the first day of the month, if you only know the year, enter January 1st",
        nameHelp: "Whole name of person",
        verify: "Verify",
        countries: "Countries",
        birthdate: "Bidthdate",
        name: "Name"
    },
    utils: {
        select: "Select",
        selected: "Selected",
        deselect: "Remove",
        more: "+ {count} more"
    }
}


export default en_msg;